const Footer = ({ randomDate }) => {

  return (
    <footer>
      <button id='random' onClick={randomDate}>Random</button>
    </footer>
  )

}

export default Footer